/** @jsx jsx */
import React, {useEffect,useState} from "react"
import { Global } from "@emotion/core"
import { Box, Container, jsx } from "theme-ui"
import "typeface-ibm-plex-sans"
import SEO from "./seo"
import Header from "./header"
import Footer from "./footer"
import CodeStyles from "../styles/code"
import SkipNavLink from "./skip-nav"
import {navigate} from 'gatsby'

type LayoutProps = { children: React.ReactNode; className?: string }



const Layout = ({ children, className = `` }: LayoutProps) => {
  const [gew,setGew]=useState(true)
  useEffect(()=>{
    //if (!typeof window) return
    let j =  window.localStorage.getItem('blogZug')
    console.log(j)
    if(j) {
      setGew(true)
    }
    else{
      setGew(false) 
      navigate("/datenschutz")
    }
  },[gew])

 function pruef(){
    if(!typeof window) return
    window.localStorage.setItem("blogZug",true)
    navigate("/")
    return setGew(true)
  }
  return (
  <React.Fragment>
    <Global
      styles={(theme) => ({
        "*": {
          boxSizing: `inherit`,
        },
        html: {
          WebkitTextSizeAdjust: `100%`,
        },
        
        img: {
          borderStyle: `none`,
        },
        pre: {
          fontFamily: `monospace`,
          fontSize: `1em`,
        },
        "[hidden]": {
          display: `none`,
        },
        "::selection": {
          backgroundColor: theme.colors.text,
          color: theme.colors.background,
        },
        a: {
          transition: `all 0.3s ease-in-out`,
          color: `text`,
        },
       blockquote:{
         border: 'none !important',
        
       }
      })}
    />
    <SEO />
    <SkipNavLink>Skip to content</SkipNavLink>
{ !gew &&
    <Container sx={{background:'tomato'}}>
      <p>Der Besuch der Webseite ist nur möglich, wenn Sie unseren Datenschutzbestimmungen zustimmen. <br/>
      Wir setzen Cookies zu technischen Verwaltung und nutzen zur Verbesserung unserer Webseite Google Analytics nach 
      den Rahmenrichtlinien der europäischen Datenschutzverordnung.<br/>
      Sie können auf dieser Seite Browser Einstellungen vornehmen, die Ihnen einen höheren Datenschutz gewährleisten.
      Google Analytics wird erst nach der Fertigstellung der Webseite aktiviert.<br/>

      Bis dahin werden nur die üblichen technischen Informationen temporär gespeichert, die zum Betreiben einer Webseite notwendig sind.
      (Browsertype, IP-Adresse, etc). Diese Daten werden nicht mit ihrer Person in irgendeinen Zusammenhang gebracht.
      </p>
       <button  style={{textAlign:'center',fontSize:'1rem',margin:'1%',border:'none',padding:"1%",background:'tomato',color:'white'}}>
    Ich möchte Ihre Datenschutzbestimmungen lesen.
  </button> {' '}
    <button onClick={()=>pruef()} style={{fontSize:'1rem',textAlign:'center',border:'none',margin:'1%',padding:"1%",background:'red',color:'white'}}>
      Ich stimme Ihren Datenschutzbestimmungen zu.
    </button>
   
  </Container>
}

    <Container>
      <Header />
      <Box id="skip-nav" sx={{ ...CodeStyles }} className={className}>
        {children}
      </Box>
      <Footer />
    </Container>
  </React.Fragment>
)}

export default Layout
