/** @jsx jsx */
import { jsx} from "theme-ui"
import useSiteMetadata from "../hooks/use-site-metadata"

const Footer = () => {
  const { siteTitle } = useSiteMetadata()

  return (
    <footer
      sx={{
        boxSizing: `border-box`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        mt: [6],
        color: `secondary`,
        a: {
          variant: `links.secondary`,
        },
        flexDirection: `column`,
        variant: `dividers.top`,
        margin: `auto`
      }}
    >
      

<span sx={{color:`text`,mb:2}}><a href="https://markt1-bacharach.de"></a></span>
<img src="/images/logo_s.jpg" width="90"  alt="Logo" />


        <p sx={{color:`text`,mt:2}}>  &copy;  {new Date().getFullYear()} by {siteTitle}. </p><p sx={{padding:0,margin:0,marginTop:-10}}>All rights reserved.</p>
      

    </footer>
  )
}

export default Footer
