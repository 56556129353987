/** @jsx jsx */
import { Link } from "gatsby"
import { jsx,Box } from "theme-ui"
import replaceSlashes from "../utils/replaceSlashes"
import useSiteMetadata from "../hooks/use-site-metadata"
import useMinimalBlogConfig from "../hooks/use-minimal-blog-config"

const HeaderTitle = () => {
  const { siteTitle } = useSiteMetadata()
  const { basePath } = useMinimalBlogConfig()

  return (
    <Link
      to={replaceSlashes(`/${basePath}`)}
      aria-label={`${siteTitle} - Back to home`}
      sx={{ color: `heading`, textDecoration: `none` }}
    >
      <h1 sx={{ my: 0, fontWeight: `400`, fontSize: [2,3,4] }}><Box><span sx={{fontWeight:900,fontSize:[5,6]}}>R.O.M.</span></Box>{siteTitle}<br/><span style={{fontSize:'1.2rem',marginTop:`-10px`}}>von Prof. Dr. Dieter Kramer</span> </h1>
    </Link>
  )
}

export default HeaderTitle
